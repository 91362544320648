import {
  DateSetMethodEnum,
  FollowUpEnum,
  TaskCreate,
  TaskDepotFileName,
  TaskSettings,
  TaskTypeEnum,
} from './task.interface';
import {PrivacyEnum, Resource} from './common.interface';
import {ArchivedFilter, RecursivePartial, RoleEnum, User,} from './user.interface';
import {hasMinimalRole} from './util';
import {maxBy, sortBy} from 'lodash';
import {addBusinessDays, subBusinessDays} from 'date-fns';
import {Dossier} from './dossier.interface';
import {SkillProfileMap} from './resources.interface';

export interface Template extends Resource {
  name: string;
  description?: string;
  creatorId: string;
  tasks: TemplateTask[];
  archivedAt?: string;
}

export interface TemplateTask {
  _id?: string;
  localId?: string;
  parentTaskId?: string;
  name: string;
  description: string;
  skillProfiles?: string[];
  type: TaskTypeEnum;
  daysAfterStart?: number;
  daysAfterParent?: number;
  daysDuration?: number;
  settings: Partial<TaskSettings>;
  followUp?: FollowUpEnum;
  completionOptions: {
    depositFileNames?: TaskDepotFileName[];
    filesPrivacy?: PrivacyEnum;
    askSpentTime?: boolean;
    allowDateUpdate?: boolean;
  };
}

export type TemplateCreate = Omit<RecursivePartial<Template>, keyof Resource>;

export type TemplateUpdate = Partial<RecursivePartial<Template>> &
  Pick<Resource, '_id'>;

export interface TemplateFindParams {
  searchValue?: string;
  limit?: number;
  skip?: number;
  total?: number;
  sortField?: keyof Template | string;
  sortOrder?: 1 | -1;
  archived?: ArchivedFilter;
  isCreator?: 'true' | 'false';
}

export function getTemplateTasksMaxDuration(tasks: TemplateTask[]): number {
  if (tasks.length === 0) {
    return 0;
  }
  const max = maxBy(tasks, (t) => {
    return (t.daysAfterStart ?? 0) + (t.daysDuration ?? 0);
  });

  if (!max) {
    return 0;
  }

  return (max.daysDuration ?? 0) + (max.daysAfterStart ?? 0);
}

export const TEMPLATE_SEARCHABLE_FIELDS: string[] = ['name', 'description'];

export function canEditTemplate(template: Template, user: User): boolean {
  if (hasMinimalRole(RoleEnum.Admin, user)) {
    return true;
  }
  return template.creatorId === user._id;
}

export function buildTaskFromTemplateTask(
  templateTask: TemplateTask,
  template: Template,
  dossier: Dossier,
  profileBinds?: SkillProfileMap
): TaskCreate {
  const newTask: TaskCreate = {
    name: templateTask.name,
    description: templateTask.description,
    dossierId: dossier._id,
    type: templateTask.type,
    skillProfiles: templateTask.skillProfiles ?? [],
    followUp: templateTask.followUp,
    templateTaskId: templateTask._id,
    templateParentTaskId: templateTask.parentTaskId,
    ... dossier.publishedAt && {publishedAt: new Date().toISOString()},
    settings: {
      ...templateTask.settings,
    },
    completionOptions: {
      ...templateTask.completionOptions,
    },
    actorIds: [],
  };

  if (dossier.openingDate) {
    const dueAtDate = addBusinessDays(
      new Date(dossier.openingDate),
      templateTask.daysAfterStart ?? 0
    );
    newTask.dueAt = dueAtDate.toISOString();
    newTask.dateSetMethod = DateSetMethodEnum.Provisory;

    if (templateTask.type === TaskTypeEnum.Task) {
      newTask.deadlineAt = addBusinessDays(
        dueAtDate,
        templateTask.daysDuration ?? 1
      ).toISOString();
    }
  }

  templateTask.skillProfiles?.forEach((skillProfile) => {
    if (
      profileBinds?.hasOwnProperty(skillProfile) &&
      !!profileBinds[skillProfile]
    ) {
      if (
        !(newTask.actorIds as string[]).includes(profileBinds[skillProfile])
      ) {
        (newTask.actorIds as string[]).push(profileBinds[skillProfile]);
      }
    }
  });

  return newTask;
}

export function buildTasksFromTemplate(
  template: Template,
  dossier: Dossier,
  profileBinds?: SkillProfileMap
): TaskCreate[] {
  const tasks = [...template.tasks].map((templateTask) =>
    buildTaskFromTemplateTask(templateTask, template, dossier, profileBinds)
  );

  const finalTasks: TaskCreate[] = [];

  tasks.forEach((task) => {
    if (task.templateParentTaskId) {
      // dates are updated based on parent task date
      const parentTask = tasks.find(
        (t) => t.templateTaskId === task.templateParentTaskId
      );
      const templateTask = template.tasks.find(
        (t) => t._id === task.templateTaskId
      );

      if (!parentTask || !templateTask) {
        task.templateParentTaskId = undefined;
      } else {
        if (parentTask.dueAt) {
          task.dateSetMethod = DateSetMethodEnum.Provisory;
          const daysAfterParent = templateTask.daysAfterParent ?? 0;
          const daysDuration = templateTask.daysDuration ?? 1;

          if (daysAfterParent < 0) {
            const deadlineDate = addBusinessDays(
              new Date(parentTask.dueAt),
              daysAfterParent
            );
            task.deadlineAt = deadlineDate.toISOString();
            task.dueAt = subBusinessDays(
              deadlineDate,
              daysDuration
            ).toISOString();
          } else {
            const dueAtDate = addBusinessDays(
              new Date(parentTask.dueAt),
              daysAfterParent
            );
            task.dueAt = dueAtDate.toISOString();
            task.deadlineAt = addBusinessDays(
              dueAtDate,
              daysDuration
            ).toISOString();
          }
        }
      }
    }

    finalTasks.push(task);
  });

  return finalTasks;
}

export function sortTemplateTasks(tasks: TemplateTask[]): TemplateTask[] {
  const linkedTasks: TemplateTask[] = [];
  let tasksOrJalons: TemplateTask[] = [];
  const finalSort: TemplateTask[] = [];

  tasks.forEach((task) => {
    task.parentTaskId ? linkedTasks.push(task) : tasksOrJalons.push(task);
  });

  tasksOrJalons = sortBy(tasksOrJalons, 'daysAfterStart');
  tasksOrJalons.forEach((task) => {
    const relatedTasks = sortBy(
      linkedTasks.filter((t) => t.parentTaskId === task._id),
      'daysAfterParent'
    );
    finalSort.push(...relatedTasks.filter((t) => (t.daysAfterParent ?? 0) < 0));
    finalSort.push(task);
    finalSort.push(
      ...relatedTasks.filter((t) => (t.daysAfterParent ?? 0) >= 0)
    );
  });

  return finalSort;
}
